import { mapGetters } from "vuex";
import { URL } from "@/api/report.js";
import InputMoney from "@/components/inputMoney.vue";
import Status from "@/components/Status.vue";

export default {
  name: "report_331",
  components: {
    InputMoney,
    Status,
  },
  data() {
    return {
      report_331: [],
      totalTableSummary: {},
      companyName: null,
      is_load_more: false,
    };
  },
  created() {
    this.getDetailReport();
  },

  methods: {
    async getDetailReport() {
      this.$store.dispatch("setIsReportLoading", true);
      const params = {};
      params.company_id = this.getSelectedCompany.id;
      params.report_type = this.routeName;
      params.report_year = this.getReportYear;

      if (this.getSelectedCompany.id && this.getReportYear) {
        const result = await this.$request({
          url: URL.REPORT,
          params,
        });
        const res = result.data;

        if (res.code == 200) {
          this.report_331 = res.data.report_331;
          this.is_load_more = res.data.is_load_more;
          this.$store.dispatch("setIsDownloadable", res.data.is_downloadable);
          this.$store.dispatch("setIsDownloadProcessing", res.data.is_download_processing);
          this.$store.dispatch("setIsLargePDF", res.data.is_large_pdf);
        }
      }

      this.$store.dispatch("setIsReportLoading", false);
    },
  },

  computed: {
    ...mapGetters([
      "getSelectedCompany",
      "getReportYear",
    ]),
    routeName() {
      return this.$route.name;
    },
  },

  watch: {
    getSelectedCompany() {
      this.getDetailReport();
    },
    getReportYear() {
      this.getDetailReport();
    },
  },
};
